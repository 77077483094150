import React, { useState, useEffect } from "react";

import { useSessionStorage, pushNotification } from "@telosalliance/ui-core-framework";
import { Routing} from "./component/Routing";

import jwt_decode from "jwt-decode";
import InviteLanding from "./pages/InviteLanding";
import { Login } from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import UserInvites from "./pages/UserInvites";
import Settings from "./pages/Settings";

import LabContext from "./context/LabContext";
import LockIcon from "@material-ui/icons/LockOutlined";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.dark.css';
import "./app.css";

//import {GlobalUtils} from "./component/GlobalUtils";



const App = () => {

  const [isLoggedIn, setIsLoggedIn] = useSessionStorage(
    "tls-beacon-loggedin",
    false
  );

  const [bakedToken, setBakedToken] = useSessionStorage(
    "tls-beacon-usertoken",
    ""
  );

  const [timeKeeper, setTimeKeeper] = useState(0);

  const [authError, setAuthError] = useState();

  const [userTimeZone, setUserTimeZone] = useState("UTC");

  const loginTitle = isLoggedIn ? "Log Out" : "Log In";

  const [hideSideMenu, setHideSideMenu] = useState(false);  
  const [hideTopMenu, setHideTopMenu] = useState(false);  


  const [menuTree, setMenuTree] = useState([{
    path: "/login",
    name: "Log In",
    component: Login,
    props: { isLoggedIn, setIsLoggedIn, bakedToken, setBakedToken, setAuthError, userTimeZone, setUserTimeZone, hideSideMenu, setHideSideMenu, hideTopMenu, setHideTopMenu},
    isProtected: false,
    icon: LockIcon,
  }]);


  const adminRoutes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      props: { isLoggedIn, setIsLoggedIn, bakedToken, setBakedToken, setAuthError, userTimeZone, setUserTimeZone,hideSideMenu, setHideSideMenu, hideTopMenu, setHideTopMenu },
      isProtected: true,
      icon: DashboardIcon,
    },
    {
      path: "/users",
      name: "Users",
      component: Users,
      props: { isLoggedIn, setIsLoggedIn, bakedToken, setBakedToken, setAuthError, userTimeZone, setUserTimeZone ,hideSideMenu, setHideSideMenu, hideTopMenu, setHideTopMenu},
      isProtected: true,
      icon: PeopleAltIcon,
    },
    {
      path: "/invites",
      name: "Invites",
      component: UserInvites,
      props: { isLoggedIn, setIsLoggedIn, bakedToken, setBakedToken, setAuthError, userTimeZone, setUserTimeZone ,hideSideMenu, setHideSideMenu, hideTopMenu, setHideTopMenu},
      isProtected: true,
      icon: RecordVoiceOverIcon,
    },
    {
      path: "/settings",
      name: "Settings",
      component: Settings,
      props: { isLoggedIn, setIsLoggedIn, bakedToken, setBakedToken, setAuthError, userTimeZone, setUserTimeZone ,hideSideMenu, setHideSideMenu, hideTopMenu, setHideTopMenu},
      isProtected: true,
      icon: SettingsIcon,
    },
    {
      path: "/login",
      name: loginTitle,
      component: Login,
      props: { isLoggedIn, setIsLoggedIn, bakedToken, setBakedToken, setAuthError, userTimeZone, setUserTimeZone ,hideSideMenu, setHideSideMenu, hideTopMenu, setHideTopMenu},
      isProtected: false,
      icon: LockIcon,
    },
  ];

  const userRoutes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      props: { isLoggedIn, setIsLoggedIn, bakedToken, setBakedToken, setAuthError, userTimeZone, setUserTimeZone ,hideSideMenu, setHideSideMenu, hideTopMenu, setHideTopMenu},
      isProtected: true,
      icon: DashboardIcon,
    },
    {
      path: "/invites",
      name: "Invites",
      component: UserInvites,
      props: { isLoggedIn, setIsLoggedIn, bakedToken, setBakedToken, setAuthError, userTimeZone, setUserTimeZone ,hideSideMenu, setHideSideMenu, hideTopMenu, setHideTopMenu},
      isProtected: true,
      icon: RecordVoiceOverIcon,
    },
    {
      path: "/login",
      name: loginTitle,
      component: Login,
      props: { isLoggedIn, setIsLoggedIn, bakedToken, setBakedToken, setAuthError, userTimeZone , setUserTimeZone ,hideSideMenu, setHideSideMenu, hideTopMenu, setHideTopMenu},
      isProtected: false,
      icon: LockIcon,
    },
    
  ];

  const apiRoutes = [
    {
      path: "/login",
      name: loginTitle,
      component: Login,
      props: { isLoggedIn, setIsLoggedIn, bakedToken, setBakedToken, setAuthError, userTimeZone, setUserTimeZone ,hideSideMenu, setHideSideMenu, hideTopMenu, setHideTopMenu},
      isProtected: false,
      icon: LockIcon,
    },
  ];

  const doNavigate = (url) =>{
    //window.location.replace(url);
    window.location.href = url;
  }

  useEffect(() => {
    if (bakedToken) {

      /*TODO*/ // add try/catch
      const bakedJson = JSON.parse(atob(bakedToken)); //decode base64

     // console.log("userJson",userJson);

      const decodedToken = jwt_decode(bakedJson.token);

      //console.log("decodedToken",decodedToken);
      
      if (decodedToken) {

       
        switch (decodedToken.role) {
          case "Admin":
            setMenuTree(adminRoutes);
            break;

          case "User":
            setMenuTree(userRoutes);
            break;

          default:
            setMenuTree(apiRoutes);
            break;
        }
      }
    } else {
      setMenuTree(apiRoutes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bakedToken]);


  useEffect(() => {

      if(authError && authError.response) {

       // console.log("*** authError", authError);
      //  console.log("*** status 1:", authError.response.status);

        // 401 = Unauthorized, 403 = Forbidden
        if (authError.response.status === 401 || authError.response.status === 403) {
         
          pushNotification("ERROR - Your not authorized, logging out... ("+authError.response.status+")");

          sessionStorage.removeItem('tls-beacon-loggedin');  
          sessionStorage.removeItem('tls-beacon-usertoken');

          setMenuTree(apiRoutes);

          setTimeout(() => {
            doNavigate("./login");
          }, 100);

          
        } else {

            console.log("status 2: ", authError.message);
            pushNotification("ERROR - " + authError.response.status);
        }
        /*else {
          if(only403 === undefined || only403 === false) {
            pushNotification("ERROR (" + title + "): " + err.message);
            console.log("ERROR (" + title + "): ", err.message);
          }
        }*/
      } else {
        
        if(authError === "noToken") {

          pushNotification("ERROR - Your not authorized, logging out... (no token)");

          sessionStorage.removeItem('tls-beacon-loggedin');  
          sessionStorage.removeItem('tls-beacon-usertoken');

          setMenuTree(apiRoutes);

          setTimeout(() => {
            doNavigate("./login/");
          }, 100);

        }
      }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authError]);

  useEffect(() => {
    const timer=setTimeout(() => {
      setTimeKeeper(new Date());
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });




 // console.log(" window.location.search", window.location.search);

  // do we want to show the INVITE PAGE or the pages?
  if(window.location.search.startsWith("?c=") || 
    window.location.search.startsWith("?lite=") || 
    window.location.search.startsWith("?iqs=")) 
  {

    //Show invite page
    //console.log("App - InviteLanding, isLoggedIn:", isLoggedIn);
    return (
      <> 
        <Routing
        isLoggedIn={isLoggedIn}
        home={{
          component: InviteLanding,
          props: { isLoggedIn, setIsLoggedIn, bakedToken, setBakedToken, authError, setAuthError, hideSideMenu, setHideSideMenu, hideTopMenu, setHideTopMenu },
        }}
        routes={apiRoutes}
      >
      </Routing>
      
      </>
    );

  } else {

    // Show login and pages
    //console.log("App - Login, isLoggedIn:", isLoggedIn);
    return (
      <>
    
      <LabContext.Provider value={timeKeeper}>

      <Routing
        isLoggedIn={isLoggedIn}
        home={{
          component: Login,
          props: { isLoggedIn, setIsLoggedIn, bakedToken, setBakedToken, authError, setAuthError, hideSideMenu, setHideSideMenu, hideTopMenu, setHideTopMenu },
        }}
        routes={menuTree}
      >
      </Routing>

      </LabContext.Provider>
      </>
    );
  };

}

export default App;
