import React, { useEffect, useState } from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { SnackbarProvider, useSnackbar } from "notistack";
import { Modal, Button, Input } from "@telosalliance/ui-core";
import { CSSTransition } from "react-transition-group";
import LinearProgress from '@material-ui/core/LinearProgress';

/* 
  UICoreRoot

  Handles global stuff:
    * modal functions
    * notifications
    * loading indicator
*/

/* ErrorOutline */
const CheckIcon = (
  <SvgIcon>
    <path
      d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,
        4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,
        0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z"
    />
  </SvgIcon>
);

const ErrorIcon = (
  <SvgIcon>
    <path
      d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,
        20 12,20M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22C17.53,22 22,17.53 22,12C22,6.47 17.53,
        2 12,2M14.59,8L12,10.59L9.41,8L8,9.41L10.59,12L8,14.59L9.41,16L12,13.41L14.59,16L16,
        14.59L13.41,12L16,9.41L14.59,8Z"
    />
  </SvgIcon>
);

const WarningIcon = (
  <SvgIcon>
    <path d="M13,14H11V10H13M13,18H11V16H13M1,21H23L12,2L1,21Z" />
  </SvgIcon>
);

const InfoIcon = (
  <SvgIcon>
    <path
      d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,
        12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,
        10 0 0,0 12,2M11,17H13V11H11V17Z"
    />
  </SvgIcon>
);

const variantIcons = {
  success: CheckIcon,
  error: ErrorIcon,
  warning: WarningIcon,
  info: InfoIcon,
};
const variantColors = {
  success: "green",
  error: "grey",
  warning: "orange",
  info: "blue",
};

const EV_NOTIFICATION = "UIC_EV_NOTFICATION";
const EV_MODAL_REQUEST = "UIC_EV_MODAL_REQUEST";
const EV_MODAL_RESPONSE = "UIC_EV_MODAL_RESPONSE";
const EV_MODAL_CLOSE_ALL = "UIC_EV_MODAL_CLOSE_ALL";

const dispatchEvent = (event, detail) =>
  window.dispatchEvent(new CustomEvent(event, { detail }));

/*-----------------------------------------------------------------------
  Modal
-----------------------------------------------------------------------*/

const UICRootModal = () => {
  const [modal, setModalState] = useState({
    open: false,
    type: null,
    title: null,
    message: null,
    variant: undefined,
    okButtonProps: undefined,
    // prompt:
    autoSelect: false,
    promptValue: "",
  });

  const isPrompt = modal.type === "prompt";
  const isNotAlert = modal.type !== "alert";

  const handleModalRequest = ({ detail }) => {
    setModalState({
      ...detail,
      open: true,
    });
  };

  const handleCloseAll = () => {
    setModalState({ open: false });
  };

  useEffect(() => {
    window.addEventListener(EV_MODAL_REQUEST, handleModalRequest);
    window.addEventListener(EV_MODAL_CLOSE_ALL, handleCloseAll);
    return () => {
      window.removeEventListener(EV_MODAL_REQUEST, handleModalRequest);
      window.removeEventListener(EV_MODAL_CLOSE_ALL, handleCloseAll);
    };
  });

  const handleClickOk = () => {
    const response = isPrompt ? modal.promptValue : true;
    setModalState({ open: false });
    dispatchEvent(EV_MODAL_RESPONSE, { response });
  };

  const handleClickCancel = () => {
    setModalState({ open: false });
    dispatchEvent(EV_MODAL_RESPONSE, { response: false });
  };

  const { okButtonProps = {}, variant } = modal;

  const okButton = (
    <Button
      onClick={handleClickOk}
      color={(variant && variantColors[variant]) || "blue"}
      style={{ minWidth: 80 }}
      {...okButtonProps}
    >
      {okButtonProps.text || "OK"}
    </Button>
  );

  const cancelButton = isNotAlert && (
    <Button onClick={handleClickCancel}>Cancel</Button>
  );

  const promptInput = isPrompt && (
    <>
      <br />
      <Input
        className="uic-modal-content-promptInput"
        autoFocus={true}
        autoSelect={modal.autoSelect}
        value={modal.promptValue}
        onChange={val =>
          setModalState(state => ({ ...state, promptValue: val }))
        }
      />
    </>
  );

  return (
    <Modal
      open={modal.open}
      className={variant ? `uic-modal-variant uic-modal-${variant}` : ""}
      buttons={
        <>
          {cancelButton}
          {okButton}
        </>
      }
      onEscapeKeyDown={handleClickCancel}
      onEnterKeyDown={handleClickOk}
      role="alert"
    >
      {variantIcons[variant]}
      <span>{modal.message}</span>
      {promptInput}
    </Modal>
  );
};

const showModal = type => (message = null, options = {}) => {
  const detail = {
    ...options,
    message,
    type,
  };

  dispatchEvent(EV_MODAL_REQUEST, detail);

  const promise = new Promise((resolve, reject) => {
    const handleResponse = ({ detail }) => {
      const resp = type !== "alert" ? detail.response : undefined;
      resolve(resp);

      window.removeEventListener(EV_MODAL_RESPONSE, handleResponse);
    };

    window.addEventListener(EV_MODAL_RESPONSE, handleResponse);
  });

  return promise;
};

const alert = showModal("alert");
const confirm = showModal("confirm");
const prompt = showModal("prompt");
const closeAllModals = () => dispatchEvent(EV_MODAL_CLOSE_ALL);

/*-----------------------------------------------------------------------
  Notifications
-----------------------------------------------------------------------*/

const UICRootNotifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleNotif = ({ detail }) => {
    const { close, message, ...options } = detail;

    if (close === true) {
      closeSnackbar(options.key);
    } else {
      enqueueSnackbar(message, options);
    }
  };

  useEffect(() => {
    window.addEventListener(EV_NOTIFICATION, handleNotif);
    return () => {
      window.removeEventListener(EV_NOTIFICATION, handleNotif);
    };
  });

  return null;
};

const pushNotification = (message, options) => {
  const key = new Date().getTime() + Math.random();

  const detail = {
    message,
    key,
    variant: "error",
    action: closeBtn(key),
    ...options,
  };

  dispatchEvent(EV_NOTIFICATION, detail);

  return key;
};

const closeNotification = key => {
  dispatchEvent(EV_NOTIFICATION, { close: true, key });
};

const closeBtn = key => (
  <button
    className="uic-snackbar-closeBtn"
    onClick={() => {
      closeNotification(key);
    }}
  >
    ✕
  </button>
);

/*-----------------------------------------------------------------------
  Loading Bar
-----------------------------------------------------------------------*/
let setLoading = () => console.error("setLoading not ready - please report this!");

const LoadingBar = () => {
  const [isLoading, setIsLoading] = useState(false);
  setLoading = setIsLoading;

  return (
    <CSSTransition in={isLoading} timeout={500} classNames="uic-loadingBar">
      <LinearProgress className="uic-loadingBar" />
    </CSSTransition>
  )
};

/*-----------------------------------------------------------------------
  UICoreRoot
-----------------------------------------------------------------------*/

const UICoreRoot = ({ children }) => (
  <SnackbarProvider
    className="uic-snackbar"
    classes={{
      variantSuccess: "uic-snackbar-success",
      variantError: "uic-snackbar-error",
      variantWarning: "uic-snackbar-warning",
      variantInfo: "uic-snackbar-info",
    }}
    maxSnack={3}
    transitionDuration={{ exit: 120, enter: 150 }}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
  >
    <LoadingBar />
    <UICRootNotifications />
    <UICRootModal />
    {children}
  </SnackbarProvider>
);

export {
  UICoreRoot,
  pushNotification,
  closeNotification,
  alert,
  confirm,
  prompt,
  closeAllModals,
  setLoading,
};