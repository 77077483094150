import React, { Component } from 'react';
import { 
  pushNotification,  
  confirm, 
  alert,
  prompt
} from "@telosalliance/ui-core-framework";

import {
    Panel,
    Table,
    IconButton,
    Modal,
    Button,
    Select 
  } from "@telosalliance/ui-core";


  import axios from "axios";

  import DeleteIcon from '@material-ui/icons/Delete';
  import EditIcon from '@material-ui/icons/Edit';
  import AddBoxIcon from '@material-ui/icons/AddBox';

  import { 
      ArrowDropUp,
      ArrowDropDown,
  } from '@material-ui/icons';
//import { Button } from 'devextreme-react/color-box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import SaveIcon from '@material-ui/icons/Save';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

class Settings extends Component {

  constructor(props) {
    super(props);

    this.state = {
        //isLoggedIn : props.isLoggedIn,
        isLoaded:false,
        selectedIceId: 1, //always use id 1 (we only have one icesetup)
        iceConfigs:[],
        iceConfigItems:[],
        editModalOpen: false,
        allowNew: false,
        deleteDialogOpen:false,
        deleteSelectedId: 0,
        currentIceItem: {},
        isEditMode: false,
        iceType:0,
        axiosConfig:"",
        isUnAuthorized:false,
        agentGroupGUID:'',
        userGUID:'',
        userRole:'',
        userTimeZone:'',

        emailServerType: "smtp",
        emailSmtp : 
            {
              server: "",
              port: 587,
              user: "",
              password: "",
              defaultFromEmail: "",
              defaultFromName: "Telos LiveNet",
              defaultSubject: "LiveNet Invite",
          },

        emailMailJet: {
          defaultFromEmail: "",
          defaultFromName: "Telos LiveNet",
          defaultSubject: "LiveNet Invite",
          apikey:"",
          apisecret: ""
        },

        emailMailGun: {
          defaultFromEmail: "",
          defaultFromName: "Telos LiveNet",
          defaultSubject: "LiveNet Invite",
          apikey:"",
          apidomain: ""
        },

        emailSendGrid: {
          defaultFromEmail: "",
          defaultFromName: "Telos LiveNet",
          defaultSubject: "LiveNet Invite",
          apikey:""
        },

        miscConfig: {
          serverUrl: "",
          invitePasswordLength: 8,
        },
        defaultBaseUrl:"",
        hasAppSettings: true,
        
        
        
    };

    // using this method since state is async, and can't be read direct after set.
    this.tokenHeader = "";

    this.emailServerTypes = [
        {id: "smtp", label: "SMTP"},
        {id: "mailjet", label: "Mailjet"},
        {id: "mailgun", label: "Mailgun"},
        {id: "sendgrid", label: "SendGrid"},
      ];

  }

  getToken() {
    // get "bakedToken" from session (session gets populated via login, remove __STRING__ which is added by Daren's "useSessionStorage")
    if(sessionStorage.getItem('tls-beacon-usertoken')) {
      const bakedToken = sessionStorage.getItem('tls-beacon-usertoken').replace("__STRING__", "");
      const bakedJson = JSON.parse(atob(bakedToken)); //decode base64

      //console.log("bakedJson",bakedJson)

      this.tokenHeader = { headers: { 'Authorization': 'Bearer ' + bakedJson.token, 'Content-Type': 'application/json' } }

      // use the callback in setState & it's callback, this so we have the values in the state before we use them.
      this.setState({ 
        agentGroupGUID: bakedJson.agentGroupGuid, 
        userGUID: bakedJson.userGuid, 
        userRole: bakedJson.role,
        userTimeZone: bakedJson.timeZone,
        defaultBaseUrl: window.location.origin + bakedJson.baseUrl
      }, () => {
        // load local stuff
        this.loadIceData(1);
        this.loadIceItems(1);
  
        this.loadAppSettings();

      });
      
    } else {
      this.props.setAuthError("noToken");
    }
  }  


  componentDidMount() {
 
    this.getToken(); 
    
  }

  /* TODO make Global */
  redirectToLogin = () => {
    const { history } = this.props;
    if (history) {
      sessionStorage.setItem("tls-beacon-loggedin", false);
      history.push("./login");
    }
  };

  catchUnAuthorized(err, title) {
    if(!err) return;

    if(err.response) {
       // 401 = Unauthorized, 403 = Forbidden
      if (err.response.status === 401 || err.response.status === 403) {
        this.setState({isUnAuthorized:true})
        this.redirectToLogin();
      } else {
        pushNotification("ERROR (" + title + "): " + err.message);
        console.log("ERROR (" + title + "): ", err.message);
      }
    }
  }


  loadIceData(id, cfg) {
      axios.get("./api/ice", this.tokenHeader).then((res) => {

        this.setState({
          iceConfigs: res.data,
          isLoaded:true,
        });

        if(id) { // did we provide an id, if so select that
          document.getElementById("cmdIceConfig").value = id;
        }

      }).catch((err) => {
        this.catchUnAuthorized(err,"loadIceData");
      });

  }

  loadIceItems(selId){

      axios.get("./api/iceitem?id=" + selId, this.tokenHeader).then((res) => {
          const configs = res.data;

          this.setState({
              iceConfigItems: configs,
              selectedIceId: selId,
              isLoaded:true,
          });

          if(selId>0) {
              this.setState({
                  allowNew: true,
              }); 
          } else {
              this.setState({
                  allowNew: false,
              }); 
          }

        }).catch((err) => {
          this.catchUnAuthorized(err,"loadIceItems");
        });

    }

    handleNewIceItem = (iceType) => {

      // only alow one STUN server
      if(iceType === 1){
       
        var numStuns = this.state.iceConfigItems.filter(f => f.iceType===1).length;

        if(numStuns>0){
          alert("You can only have one STUN server");
          return;
        }

      }

      this.setState({ editModalOpen: true, isEditMode:false, iceType: iceType, currentIceItem:{} });

    };

    handleEditBtn = (id) => {
      
      let items = this.state.iceConfigItems; 
      let item = items.filter(f => f.iceServerItemId===id)[0]; 
      item.iceId = id;

      this.setState({ editModalOpen: true, isEditMode:true, currentIceItem: item });
    };

    handleDeleteIceItem = (id) => {
      axios.delete("./api/iceitem?id=" + id, this.tokenHeader).then((res) => {
          this.loadIceItems(this.state.selectedIceId); 

      }).catch((err) => {
        this.catchUnAuthorized(err,"handleDeleteIceItem");
      });
      
    };

    handleMoveUpBtn= (id, sortOrder) => {
        const data = {
          id: id,
          newPos: parseInt(sortOrder-150)
        }
        //console.log("handleMoveUpBtn", id, data);

        axios.post("./api/sorticeitem", data, this.tokenHeader).then((res) => {
            this.loadIceItems(this.state.selectedIceId); 
        }).catch((err) => {
          this.catchUnAuthorized(err,"handleMoveUpBtn");
        });
    };

    handleMoveDnBtn= (id, sortOrder) => {
      const data = {
        id: id,
        newPos: parseInt(sortOrder+150)
      }
      //console.log("handleMoveDnBtn", id, data);

      axios.post("./api/sorticeitem", data, this.tokenHeader).then((res) => {
            this.loadIceItems(this.state.selectedIceId); 
        }).catch((err) => {
          this.catchUnAuthorized(err,"handleMoveDnBtn");
        });

    };

    handleCloseDialog = () => {
        this.setState({ deleteDialogOpen: false });
    };



    handleIceItemSave =() => {
        
        if(this.state.isEditMode) {
            // update current IceItem
            axios
            .put("./api/iceitem", this.state.currentIceItem, this.tokenHeader)
            .then((res) => {
              //console.log("RECEIVED: ", res.data);
              this.loadIceItems(this.state.selectedIceId); 
              this.setState({ editModalOpen: false });
            })
            .catch((err) => {
              this.catchUnAuthorized(err,"handleIceItemSave (put)");
            });


        } else {

            // Add New IceItem
            const postData = {
                iceId: parseInt(this.state.selectedIceId),
                iceType: this.state.iceType,
                iceUrl: this.state.currentIceItem.iceUrl,
                user: this.state.currentIceItem.user,
                pass: this.state.currentIceItem.pass,
            }

            axios
            .post("./api/iceitem", postData, this.tokenHeader)
            .then((res) => {
                //console.log("RECEIVED: ", res.data);
                this.loadIceItems(this.state.selectedIceId); 
                this.setState({ editModalOpen: false });
            })
            .catch((err) => {
              this.catchUnAuthorized(err,"handleIceItemSave (post)");
            });

        }

        
    }

    handleIceItem =  (event) => {
        // console.log(event.target.id, event.target.value);
        const dataType = event.target.getAttribute("data-type");
        const item = event.target.id;
        const data = event.target.value;
    
        const updatedItems = { ...this.state.currentIceItem };
        if (dataType) {
          switch(dataType){
            case "int":
              updatedItems[item] = parseInt(data);  
            break;
            case "double":
              updatedItems[item] = parseFloat(data);  
            break;
            case "bool":
              updatedItems[item] = JSON.parse(data);  
            break;
            case "string":
              updatedItems[item] = data;  
            break;
            default:
              updatedItems[item] = data;  
              break;
          }
        } else {
          updatedItems[item] = data;  
        }

        this.setState({ currentIceItem: updatedItems });
    }


    handleAddIceConfig = (newTitle) => {
        console.log("handleAddIceConfig", newTitle)
        
        if(newTitle !== false) {
          
          const data = {
              title: newTitle,
              description: ''
          }

          axios.post("./api/ice",data, this.tokenHeader).then((res) => {
             
             this.loadIceData(res.data.id);
             this.loadIceItems(res.data.id); 

              pushNotification("Ice Config added: " + newTitle , {
                variant: "success",
              });
    

          }).catch((err) => {
            this.catchUnAuthorized(err,"handleAddIceConfig");
          });

        }
       
    };
    
    handleDeleteIceConfig = (event) => {
      
      axios.delete("./api/ice?id=" + this.state.selectedIceId, this.tokenHeader).then((res) => {
          this.loadIceData(); 

          this.setState({
            iceConfigItems: [],
            selectedIceId: 0,
            allowNew: false,
        });

      

      }).catch((err) => {
        this.catchUnAuthorized(err,"handleDeleteIceConfig");
      });

      //

    };

    renderModalEdit() {

        let editModeTitle = "Add New";
        if(this.state.isEditMode) {editModeTitle = "Edit";}

       
        let saveDisabled = true;
      
        // for TURN check that we enterd url/user/pwd
        if(this.state.iceType === 2) {
          if(this.state.currentIceItem.iceUrl && this.state.currentIceItem.user && this.state.currentIceItem.pass) {
            saveDisabled = false;
          } else {
            saveDisabled = true;
          }
        } else {
           // for STUN check that we enterd url
          if(this.state.currentIceItem.iceUrl) {
            saveDisabled = false;
          }
        }

        return (
          <Modal
              open={this.state.editModalOpen}
              className="session-inviteModal"
              onEscapeKeyDown={() => this.setState({ editModalOpen: false })}
              buttons={[
                <button key="uic_btnCancel" type="button" className="uic-btn btn-grey" onClick={() => this.setState({ editModalOpen: false })} >
                  Cancel
                </button>,
                <button key="uic_btnAdd" disabled={saveDisabled} type="button" className="uic-btn btn-primary" onClick={() => this.handleIceItemSave()} color="primary">Save</button>,
              ]}
            >
              <h2 className="panel-subSection-header">{editModeTitle}</h2>
             
              <Table
                alignLabelsLeft
                yPad={10}
                rows={[
                  ["URL", 
                  <input 
                    className="uic-input inpWith25vw" 
                    id="iceUrl" 
                    placeholder="URL" 
                    name="iceUrl" 
                    autoComplete="off" 
                    type="text"
                    required 
                    defaultValue={this.state.currentIceItem.iceUrl}
                    onChange={this.handleIceItem} 
                    data-type="string" 
                  />],
                  ["User", 
                  <input 
                    className="uic-input inpWith25vw" 
                    id="user" 
                    placeholder="User" 
                    name="user" 
                    autoComplete="off" 
                    type="text"
                    defaultValue={this.state.currentIceItem.user}
                    onChange={this.handleIceItem} 
                    data-type="string" 
                  />],
                  ["Password", 
                  <input 
                    className="uic-input inpWith25vw" 
                    id="pass" 
                    placeholder="Password" 
                    name="pass" 
                    autoComplete="off" 
                    type="text" 
                    defaultValue={this.state.currentIceItem.pass}
                    onChange={this.handleIceItem} 
                    data-type="string" 
                  />]
                  
                ]}
              />
               
            </Modal>
        )
    }

    
    updateGlobalSignaling = () => {

    }


    /* Email */

    handleEmailItemSmtp =  (event) => {
        // console.log(event.target.id, event.target.value);
        const dataType = event.target.getAttribute("data-type");
        const item = event.target.id;
        const data = event.target.value;
    
        const updatedItems = { ...this.state.emailSmtp };
        if (dataType) {
          switch(dataType){
            case "int":
              updatedItems[item] = parseInt(data);  
            break;
            case "double":
              updatedItems[item] = parseFloat(data);  
            break;
            case "bool":
              updatedItems[item] = JSON.parse(data);  
            break;
            case "string":
              updatedItems[item] = data;  
            break;
            default:
              updatedItems[item] = data;  
              break;
          }
        } else {
          updatedItems[item] = data;  
        }

        this.setState({ emailSmtp: updatedItems });
    }

    handleEmailItemMailJet =  (event) => {
      // console.log(event.target.id, event.target.value);
      const dataType = event.target.getAttribute("data-type");
      const item = event.target.id;
      const data = event.target.value;
  
      const updatedItems = { ...this.state.emailMailJet };
      if (dataType) {
        switch(dataType){
          case "int":
            updatedItems[item] = parseInt(data);  
          break;
          case "double":
            updatedItems[item] = parseFloat(data);  
          break;
          case "bool":
            updatedItems[item] = JSON.parse(data);  
          break;
          case "string":
            updatedItems[item] = data;  
          break;
          default:
            updatedItems[item] = data;  
            break;
        }
      } else {
        updatedItems[item] = data;  
      }

      this.setState({ emailMailJet: updatedItems });
  }

  handleEmailItemMailGun =  (event) => {
    // console.log(event.target.id, event.target.value);
    const dataType = event.target.getAttribute("data-type");
    const item = event.target.id;
    const data = event.target.value;

    const updatedItems = { ...this.state.emailMailGun};
    if (dataType) {
      switch(dataType){
        case "int":
          updatedItems[item] = parseInt(data);  
        break;
        case "double":
          updatedItems[item] = parseFloat(data);  
        break;
        case "bool":
          updatedItems[item] = JSON.parse(data);  
        break;
        case "string":
          updatedItems[item] = data;  
        break;
        default:
          updatedItems[item] = data;  
          break;
      }
    } else {
      updatedItems[item] = data;  
    }

    this.setState({ emailMailGun: updatedItems });
}

handleEmailItemSendGrid =  (event) => {
  // console.log(event.target.id, event.target.value);
  const dataType = event.target.getAttribute("data-type");
  const item = event.target.id;
  const data = event.target.value;

  const updatedItems = { ...this.state.emailSendGrid };
  if (dataType) {
    switch(dataType){
      case "int":
        updatedItems[item] = parseInt(data);  
      break;
      case "double":
        updatedItems[item] = parseFloat(data);  
      break;
      case "bool":
        updatedItems[item] = JSON.parse(data);  
      break;
      case "string":
        updatedItems[item] = data;  
      break;
      default:
        updatedItems[item] = data;  
        break;
    }
  } else {
    updatedItems[item] = data;  
  }

  this.setState({ emailSendGrid: updatedItems });
}

handleMiscConfig =  (event) => {
  // console.log(event.target.id, event.target.value);
  const dataType = event.target.getAttribute("data-type");
  const item = event.target.id;
  const data = event.target.value;

  const updatedItems = { ...this.state.miscConfig };
  if (dataType) {
    switch(dataType){
      case "int":
        updatedItems[item] = parseInt(data);  
      break;
      case "double":
        updatedItems[item] = parseFloat(data);  
      break;
      case "bool":
        updatedItems[item] = JSON.parse(data);  
      break;
      case "string":
        updatedItems[item] = data;  
      break;
      default:
        updatedItems[item] = data;  
        break;
    }
  } else {
    updatedItems[item] = data;  
  }

    this.setState({ miscConfig: updatedItems });
  }



  loadAppSettings = () => {
       
      axios.get("./api/getAppSettings", this.tokenHeader).then((res) => {
      
        const settings = res.data;

        if(settings){

            this.setState({
              emailServerType: settings.emailServerType,
              emailSmtp: settings.emailSmtp,
              emailMailJet: settings.emailMailJet,
              emailMailGun: settings.emailMailGun,
              emailSendGrid: settings.emailSendGrid,
              miscConfig: settings.misc,

              hasAppSettings: true
            }, () =>{
              
              if(!this.state.miscConfig.serverUrl) {
                const miscData = this.state.miscConfig;
                miscData.serverUrl = this.state.defaultBaseUrl;
                this.setState({miscConfig: miscData})
              }
            })
        }

      }).catch((err) => {

        if(!this.state.miscConfig.serverUrl) {
          const miscData = this.state.miscConfig;
          miscData.serverUrl = this.state.defaultBaseUrl;
          this.setState({miscConfig: miscData})
        }

        //console.log("err", err);

        if(err.response) {
          // 400 = BadRequest
          if (err.response.status === 400) {
            this.setState({hasAppSettings:false})
            //pushNotification("Please configure 'App Setup'", { variant: "warning" });
          } else {
            
            this.catchUnAuthorized(err,"loadAppSettings");
          }
        }

        
      });

    }


    saveAppSettings = (showNote) => {

      const data = {
        emailServerType: this.state.emailServerType,
        emailSmtp: this.state.emailSmtp,
        emailMailJet: this.state.emailMailJet,
        emailMailGun : this.state.emailMailGun,
        emailSendGrid: this.state.emailSendGrid,
        misc : this.state.miscConfig,
        sendToEmail: "",
      }

      axios.post("./api/saveAppSettings", data, this.tokenHeader).then((res) => {
        
        this.setState({hasAppSettings: true});
        if(showNote) {
          pushNotification("App Setup was saved!", {variant: "success"});
        }

      }).catch((err) => {
        this.catchUnAuthorized(err,"saveAppSettings");
      });

    }



    handleEmailServerType = (id) =>{
        this.setState({emailServerType: id});
    }

    renderSmtpSettings() {

        if(this.state.emailServerType !== "smtp"){
          return null;
        }

        return (
              <>
                <Table
                  alignLabelsLeft
                  yPad={10}
                  xPad={20}
                  rows={[
                  ["SMTP Address", 
                  <input 
                      className="uic-input inpWith25vw" 
                      id="server" 
                      placeholder="Address" 
                      autoComplete="off" 
                      type="text"
                      required 
                      defaultValue={this.state.emailSmtp.server}
                      onChange={this.handleEmailItemSmtp} 
                      data-type="string" 
                  />],
                  ["SMTP Port", 
                  <input 
                      className="uic-input inpWith100" 
                      id="port" 
                      placeholder="Port" 
                      autoComplete="off" 
                      type="text"
                      required 
                      value={this.state.emailSmtp.port}
                      onChange={this.handleEmailItemSmtp} 
                      data-type="string" 
                  />],
                  ["SMTP User", 
                  <input 
                      className="uic-input inpWith25vw" 
                      id="user" 
                      placeholder="User" 
                      autoComplete="off" 
                      type="text"
                      required 
                      defaultValue={this.state.emailSmtp.user}
                      onChange={this.handleEmailItemSmtp} 
                      data-type="string" 
                  />],
                  ["SMTP Password", 
                  <input 
                      className="uic-input inpWith25vw" 
                      id="password" 
                      placeholder="Password" 
                      autoComplete="off" 
                      type="password"
                      required 
                      defaultValue={this.state.emailSmtp.password}
                      onChange={this.handleEmailItemSmtp} 
                      data-type="string" 
                  />],
                  ["From Email", 
                  <input 
                      className="uic-input inpWith25vw" 
                      id="defaultFromEmail" 
                      placeholder="From Email" 
                      autoComplete="off" 
                      type="text"
                      required 
                      defaultValue={this.state.emailSmtp.defaultFromEmail}
                      onChange={this.handleEmailItemSmtp} 
                      data-type="string" 
                  />],
                  ["From Name", 
                  <input 
                      className="uic-input inpWith25vw" 
                      id="defaultFromName" 
                      placeholder="From Name" 
                      autoComplete="off" 
                      type="text"
                      required 
                      defaultValue={this.state.emailSmtp.defaultFromName}
                      onChange={this.handleEmailItemSmtp} 
                      data-type="string" 
                  />],
                  ["Email Subject", 
                  <input 
                      className="uic-input inpWith25vw" 
                      id="defaultSubject" 
                      placeholder="Email Subject" 
                      autoComplete="off" 
                      type="text"
                      required 
                      defaultValue={this.state.emailSmtp.defaultSubject}
                      onChange={this.handleEmailItemSmtp} 
                      data-type="string" 
                  />]
              
              ]}
              /> 
          
            </>
          )
      
    }

    renderMailJetSettings() {

      if(this.state.emailServerType !== "mailjet"){
        return null;
      }

      return (
            <>

            <Table
              params 
              alignLabelsLeft
              yPad={10}
              xPad={20}
              rows={[
              ["Api Key", 
              <input 
                  className="uic-input inpWith25vw" 
                  id="apikey" 
                  placeholder="Key" 
                  autoComplete="off" 
                  type="text"
                  required 
                  defaultValue={this.state.emailMailJet.apikey}
                  onChange={this.handleEmailItemMailJet} 
                  data-type="string" 
              />],
              ["Api Secret", 
              <input 
                  className="uic-input inpWith25vw" 
                  id="apisecret" 
                  placeholder="Secret" 
                  autoComplete="off" 
                  type="text"
                  required 
                  defaultValue={this.state.emailMailJet.apisecret}
                  onChange={this.handleEmailItemMailJet} 
                  data-type="string" 
              />],

              ["From Email", 
              <input 
                  className="uic-input inpWith25vw" 
                  id="defaultFromEmail" 
                  placeholder="From Email" 
                  autoComplete="off" 
                  type="text"
                  required 
                  defaultValue={this.state.emailMailJet.defaultFromEmail}
                  onChange={this.handleEmailItemMailJet} 
                  data-type="string" 
              />],
              ["From Name", 
              <input 
                  className="uic-input inpWith25vw" 
                  id="defaultFromName" 
                  placeholder="From Name" 
                  autoComplete="off" 
                  type="text"
                  required 
                  defaultValue={this.state.emailMailJet.defaultFromName}
                  onChange={this.handleEmailItemMailJet} 
                  data-type="string" 
              />],
              ["Email Subject", 
              <input 
                  className="uic-input inpWith25vw" 
                  id="defaultSubject" 
                  placeholder="Email Subject" 
                  autoComplete="off" 
                  type="text"
                  required 
                  defaultValue={this.state.emailMailJet.defaultSubject}
                  onChange={this.handleEmailItemMailJet} 
                  data-type="string" 
              />]
          
          ]}
          /> 
   
          </>
        )
  }

  renderMailGunSettings() {

    if(this.state.emailServerType !== "mailgun"){
      return null;
    }

    return (
          <>

          <Table
            params 
            alignLabelsLeft
            yPad={10}
            xPad={20}
            rows={[
            ["Api Key", 
            <input 
                className="uic-input inpWith25vw" 
                id="apikey" 
                placeholder="Api Key" 
                autoComplete="off" 
                type="text"
                required 
                defaultValue={this.state.emailMailGun.apikey}
                onChange={this.handleEmailItemMailGun} 
                data-type="string" 
            />],
            ["Api Domain", 
            <input 
                className="uic-input inpWith25vw" 
                id="apidomain" 
                placeholder="Api Domain" 
                autoComplete="off" 
                type="text"
                required 
                defaultValue={this.state.emailMailGun.apidomain}
                onChange={this.handleEmailItemMailGun} 
                data-type="string" 
            />],

            ["From Email", 
            <input 
                className="uic-input inpWith25vw" 
                id="defaultFromEmail" 
                placeholder="From Email" 
                autoComplete="off" 
                type="text"
                required 
                defaultValue={this.state.emailMailGun.defaultFromEmail}
                onChange={this.handleEmailItemMailGun} 
                data-type="string" 
            />],
            ["From Name", 
            <input 
                className="uic-input inpWith25vw" 
                id="defaultFromName" 
                placeholder="From Name" 
                autoComplete="off" 
                type="text"
                required 
                defaultValue={this.state.emailMailGun.defaultFromName}
                onChange={this.handleEmailItemMailGun} 
                data-type="string" 
            />],
            ["Email Subject", 
            <input 
                className="uic-input inpWith25vw" 
                id="defaultSubject" 
                placeholder="Email Subject" 
                autoComplete="off" 
                type="text"
                required 
                defaultValue={this.state.emailMailGun.defaultSubject}
                onChange={this.handleEmailItemMailGun} 
                data-type="string" 
            />]
        
        ]}
        /> 
        </>
      )
  
  } 

  renderMailSendGridSettings() {

    if(this.state.emailServerType !== "sendgrid"){
      return null;
    }

    return (
          <>

          <Table
            params 
            alignLabelsLeft
            yPad={10}
            xPad={20}
            rows={[
            ["Api Key", 
            <input 
                className="uic-input inpWith25vw" 
                id="apikey" 
                placeholder="Api Key" 
                autoComplete="off" 
                type="text"
                required 
                defaultValue={this.state.emailSendGrid.apikey}
                onChange={this.handleEmailItemSendGrid} 
                data-type="string" 
            />],
            
            ["From Email", 
            <input 
                className="uic-input inpWith25vw" 
                id="defaultFromEmail" 
                placeholder="From Email" 
                autoComplete="off" 
                type="text"
                required 
                defaultValue={this.state.emailSendGrid.defaultFromEmail}
                onChange={this.handleEmailItemSendGrid} 
                data-type="string" 
            />],
            ["From Name", 
            <input 
                className="uic-input inpWith25vw" 
                id="defaultFromName" 
                placeholder="From Name" 
                autoComplete="off" 
                type="text"
                required 
                defaultValue={this.state.emailSendGrid.defaultFromName}
                onChange={this.handleEmailItemSendGrid} 
                data-type="string" 
            />],
            ["Email Subject", 
            <input 
                className="uic-input inpWith25vw" 
                id="defaultSubject" 
                placeholder="Email Subject" 
                autoComplete="off" 
                type="text"
                required 
                defaultValue={this.state.emailSendGrid.defaultSubject}
                onChange={this.handleEmailItemSendGrid} 
                data-type="string" 
            />]
        
        ]}
        /> 
 
        </>
      )
}


  sendTestEmail = (emailAdr) => {

    const data = {
      emailServerType: this.state.emailServerType,
      emailSmtp: this.state.emailSmtp,
      emailMailJet: this.state.emailMailJet,
      emailMailGun : this.state.emailMailGun,
      emailSendGrid: this.state.emailSendGrid,
      misc : this.state.miscConfig,
      sendToEmail: emailAdr
    }

    pushNotification("Sending email to: " + emailAdr , {
      variant: "success",
    });

    axios.post("./api/sendTestEmail", data, this.tokenHeader).then((res) => {
       // console.log("sendTestEmail OK", res);

        pushNotification("Email has been successfully sent to: " + emailAdr , {
          variant: "success",
        });

    }).catch((err) => {

      if(err.response) {
        console.log("err.response:", err.response)
       // 400 = BadRequest
       if (err.response.status === 400) {
          if(err.response.data && err.response.data.message) {
            pushNotification(err.response.data.message);
          }
        } else {
          this.catchUnAuthorized(err,"sendTestEmail");
        }
      }
    });
  }

    /* end email */ 


    render() { 

        if(!this.state.isLoaded) {
          return("");
        }

        if(this.state.isUnAuthorized){
          return(<h1>Un-Authorized</h1>);
        }

        const stunItems = this.state.iceConfigItems.filter(f => f.iceType===1); 
        const turnItems = this.state.iceConfigItems.filter(f => f.iceType===2); 

        return ( 
            <React.Fragment>
                {this.renderModalEdit()}
            
                <Panel title={"Ice Setup"}>

                      
                    <Panel title={<><span style={{fontSize:"15px"}} >STUN Server</span> <AddBoxIcon visibility={!this.state.allowNew ? "hidden" : "showing"} onClick={() => this.handleNewIceItem(1)} style={{position:"relative", top:"5px", color:"#48ACF4", cursor:"pointer"}}/></>}>
                    
                        <Table 
                        params 
                        striped
                        alignLabelsLeft="left"
                        headers={["URL","User","Pass", "", ""]}
                        rows={
                            stunItems.map((cfg) => 
                                [cfg.iceUrl,cfg.user,cfg.pass, 
                                <IconButton onClick={() => this.handleEditBtn(cfg.iceServerItemId)} icon={EditIcon} />,
                                <IconButton
                                icon={DeleteIcon}
                                tooltip="Remove STUN server"
                                onClick={async (event) => {
                                  event.preventDefault();
                                  if (
                                    await confirm(
                                      <>
                                        Sure you want to remove<br /><b>{cfg.iceUrl}</b>?
                                      </>,
                                      { variant: "warning" }
                                    )
                                  ) {
                                    this.handleDeleteIceItem(cfg.iceServerItemId);
                                  }
                                }}
                              />
                              ]
                            )
                        }
                        />
                    </Panel>     
                    <br/>    
                    <Panel  title={<><span style={{fontSize:"15px"}} >TURN Servers</span> <AddBoxIcon visibility={!this.state.allowNew ? "hidden" : "showing"} onClick={() => this.handleNewIceItem(2)} style={{position:"relative", top:"5px", color:"#48ACF4", cursor:"pointer"}}/></>}>
                   

                        <Table 
                        params 
                        striped
                        alignLabelsLeft="left"
                        headers={["URL","User","Pass", "", ""]}
                        rows={
                            turnItems.map((cfg) => 
                                [cfg.iceUrl,cfg.user,cfg.pass, 
                                <IconButton onClick={() => this.handleEditBtn(cfg.iceServerItemId)} icon={EditIcon} />,
                                <IconButton onClick={() => this.handleMoveUpBtn(cfg.iceServerItemId, cfg.sortOrder)} icon={ArrowDropUp} /> ,
                                <IconButton onClick={() => this.handleMoveDnBtn(cfg.iceServerItemId, cfg.sortOrder)} icon={ArrowDropDown}  /> ,
                                <IconButton
                                icon={DeleteIcon}
                                tooltip="Remove TURN server"
                                onClick={async (event) => {
                                  event.preventDefault();
                                  if (
                                    await confirm(
                                      <>
                                        Sure you want to remove<br /><b>{cfg.iceUrl}</b>?
                                      </>,
                                      { variant: "warning" }
                                    )
                                  ) {
                                    this.handleDeleteIceItem(cfg.iceServerItemId);
                                  }
                                }}
                              />
                              ],
                            )
                        }
                        />
                    
                    </Panel>        

                </Panel> 

                <br/><br/>   

                {!this.state.hasAppSettings && (
                  <MuiAlert severity="warning">Please configure 'App Setup'</MuiAlert>
                )}

               
                <Panel title={"App Setup"} >
                <Grid container spacing={3} >       

                    <Grid item xs={12} sm={6}>
                        
                        <Panel title={<><span style={{fontSize:"15px"}} >Send Email</span></>}>

                            <Table
                                alignLabelsLeft
                                yPad={10}
                                xPad={20}
                                rows={[
                                ["Email Send Mode", 
                                <><Select 
                                value={this.state.emailServerType}
                                onChange={id => this.handleEmailServerType(id)}
                            >
                                {this.emailServerTypes.map(
                                    ({id, label}) => (<option value={id} key={id}>{label}</option>)
                                )}
                                </Select>
                                
                                <button 
                                  style={{marginLeft:"10px"}}
                                  className="uic-btn btn-orange"
                                  disabled={!this.state.hasAppSettings}
                                  onClick={async (event) => {
                                    event.preventDefault();

                                        const data = await prompt(
                                          <>
                                            Please enter an send to email address
                                          </>,
                                          {
                                            autoSelect: true,
                                          }
                                        );
          
                                        if(!data) return;
                                        this.sendTestEmail(data)
                                      

                                  }}
                                ><MailOutlineIcon /> Send Test Email</button>

                                </>],
                               
                            ]}
                            />
                            <Divider />

                            {this.renderSmtpSettings()}
                            {this.renderMailJetSettings()}
                            {this.renderMailGunSettings()}
                            {this.renderMailSendGridSettings()}

                        </Panel>
                    </Grid>        
                             
                    <Grid item xs={12} sm={6}>
                          <Panel title={<><span style={{fontSize:"15px"}} >Misc</span></>}>
                            <Table
                                alignLabelsLeft
                                yPad={10}
                                xPad={20}
                                rows={[
                                ["Server Url", 
                                <input 
                                    className="uic-input inpWith25vw" 
                                    id="serverUrl" 
                                    autoComplete="off" 
                                    type="text"
                                    placeholder="Server Url"
                                    required 
                                    defaultValue={this.state.miscConfig.serverUrl}
                                    onChange={this.handleMiscConfig}
                                    data-type="string" 
                                    
                                />],
                                ["Invite Password length", 
                                <input 
                                    className="uic-input inpWith100" 
                                    id="invitePasswordLength" 
                                    autoComplete="off" 
                                    type="number"
                                    required 
                                    defaultValue={this.state.miscConfig.invitePasswordLength}
                                    onChange={this.handleMiscConfig}
                                    data-type="int" 
                                    min={0}
                                    max={64}
                                />]
                                
                            
                            ]}
                            />

                        </Panel>
                    </Grid>
                </Grid>


                  <Divider />
                  <Button color="blue" style={{marginTop:"20px",marginBottom:"10px"}} onClick={() =>{ this.saveAppSettings(true) }}><SaveIcon />Save</Button>
                </Panel>    

                <br/><br/>      

            </React.Fragment>


         );
    }

   

}



 
export default Settings;