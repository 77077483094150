import React, { Component } from 'react';
import { Panel } from "@telosalliance/ui-core";
import WarningIcon from '@material-ui/icons/Warning';

import Users from "./Users";

class FirstUse extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            userId:0,
            agentGroupGUID:null,
            userGUID: null,
            userRole: null,
            userTimeZone: null,
         }
    }


    render() { 

        return ( <>
        <br/>
        <Panel>
          <WarningIcon fontSize="large" style={{color: "orange"}} /><span style={{color: "orange", top:"-10px", position:"relative"}} >  To continue, you must change these credentials. Provide your information and usa a strong password.

            After updating this user, you will be logged out, there after please login again with your new credentials.</span>
        </Panel>
                
        <br/>

        <Users isFirstLogin={true} uid={this.props.uid} />
        
        <br/>

        
        </> );
    }
}
 
export default FirstUse;