import React, { Component } from 'react';

import Scheduler, { Resource } from 'devextreme-react/scheduler';
import Switch from 'devextreme-react/switch';
import EditIcon from '@material-ui/icons/Edit';
import CallIcon from '@material-ui/icons/Call';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from "axios";
import { IconButton } from "@telosalliance/ui-core";
import RestoreIcon from '@material-ui/icons/Restore';
import Grid from '@material-ui/core/Grid';
import BlockIcon from '@material-ui/icons/Block';


import { 
  pushNotification,  
  confirm, 
 // prompt
} from "@telosalliance/ui-core-framework";

import "./InviteCalendarView.css"

class InviteCalendarView extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            caledarData: [],
            sessions:[],
            groupByDate: true,
            showCurrentTimeIndicator: true,
            shadeUntilCurrentTime: true,
            updateInterval: 10,
            startDayHour: 0,
            endDayHour:23,
            fullDay:false,
            popupCancel:true,
            agentGroupGUID: '',
            userGUID:'',
            userRole: '',
            userTimeZone:'UTC',
            lastChanged: this.props.lastChanged,
            selectedDate: this.props.selectedDate,
            dxCalWorkspace:null,
        }

        this.schedulerRef = React.createRef();
      
       // this.currentDate = new Date(Date.now());
        this.views = [{
          type: 'day',
          name: 'Day'
        },{
          type: 'day',
          name: '2 Days',
          intervalCount: 2
        }, {
          type: 'day',
          name: '3 Days',
          intervalCount: 3
        }, {
          type: 'workWeek',
          name: 'Work Week',
        },{
          type: 'week',
          name: 'Full Week'
        }, {
          type: 'month',
          name: 'Month'
        }
        , {
          type: 'timelineWeek',
          name: 'Timeline Week'
        },{
          type: 'agenda',
          name: 'Agenda'
        }
      ];

        this.groups = ['sessionId'];
        this.name = [''];
        this.email = [''];


      this.callerAudioQuality = [{
        text: 'Default',
        id: '1|64'
      },
      {
        text: 'Speech Low (32kbps mono)',
        id: '1|32'
      },
      {
        text: 'Speech Medium (48kbps mono)',
        id: '1|48'
      },
      {
        text: 'Speech High (64kbps mono)',
        id: '1|64'
      },
      {
        text: 'Speech Great (96kbps mono)',
        id: '1|96'
      },
      {
        text: 'Speech Best (128kbps mono)',
        id: '1|128'
      },
      {
        text: 'Music Low (64kbps stereo)',
        id: '2|64'
      },
      {
        text: 'Music Medium (96kbps stereo)',
        id: '2|96'
      },
      {
        text: 'Music High (128kbps stereo)',
        id: '2|128'
      },
      {
        text: 'Music Great (192kbps stereo)',
        id: '2|192'
      },
      {
        text: 'Music Best (256kbps stereo)',
        id: '2|256'
      }
      ]; 
      
      this.callerAudioMode = [{
        text: 'Let user decide',
        id: 1,
      }, 
      {
        text: 'Recomend Headset',
        id: 2,
      },
      {
        text: 'Recomend Handset',
        id: 3,
      },
      {
        text: 'Recomend Handsfree',
        id: 4,
      },
      {
        text: 'Force Headset',
        id: 5,
      },
      {
        text: 'Force Handset',
        id: 6,
      },
      {
        text: 'Force Handsfree',
        id: 7,
      }
      ];

        // using this method since state is async, and can't be read direct after set.
        this.tokenHeader = "";
    }

    getToken() {
      // get "bakedToken" from session (session gets populated via login, remove __STRING__ which is added by Daren's "useSessionStorage")
      if(sessionStorage.getItem('tls-beacon-usertoken')) {
        const bakedToken = sessionStorage.getItem('tls-beacon-usertoken').replace("__STRING__", "");
        const bakedJson = JSON.parse(atob(bakedToken)); //decode base64

       // console.log("bakedJson",bakedJson)

        this.tokenHeader = { headers: { 'Authorization': 'Bearer ' + bakedJson.token, 'Content-Type': 'application/json' } }

        // use the callback in setState, this so we really have the values in the state before we use them.
        this.setState({ agentGroupGUID: bakedJson.agentGroupGuid, userGUID: bakedJson.userGuid , userRole: bakedJson.role, userTimeZone: bakedJson.timeZone }, () => {
          // load local stuff
         
        });

      } else {
        console.log("ERR - noToken")
       // this.props.setAuthError("noToken");
      }
  }  


    componentDidMount(){

       // console.log("calData",this.props.calData)

        this.setState(
        {
            caledarData:this.props.calData, 
            sessions:this.props.calSessions,
        })

        this.getToken();
    }

    componentDidUpdate(prevProps, prevState) {
  
      if(this.state.lastChanged !== prevProps.lastChanged){
       
        this.setState({lastChanged: prevProps.lastChanged})

        this.setState(
        {
          lastChanged: prevProps.lastChanged,
          caledarData:this.props.calData, 
          sessions:this.props.calSessions,
          selectedDate: this.props.selectedDate,
        })
      }

    }

    schedulerReady = (e) => {
       /*TODO*/ 

      /*
      Error: E1039 - A cell's position cannot be calculated. See:
      http://js.devexpress.com/error/20_2/E1039
      */


      /*
      this.setState({dxCalWorkspace: e.component._workSpace}, () => {
        this.scrollToDate();
      });
      */
      
    }

    scrollToDate(){

      if(this.state.selectedDate === undefined) {

        console.log("testScroll ERR");
        return;
      }

     // console.log("testScroll", this.state.selectedDate);

      let date = this.state.selectedDate;
      let scrollToHour = date.getHours();
      let scrollToMinute = date.getUTCMinutes();
     
      let view = this.state.dxCalWorkspace;
      let coordinates = view._getScrollCoordinates(scrollToHour, scrollToMinute, date); // scroll to hour, min, date...
      const scrollable = view.getScrollable();
      scrollable.scrollBy({
        top: coordinates.top - scrollable.scrollTop() - 10,
        left: coordinates.left || 0
      });
    }


    onGroupByDateChanged = (args) => {
      this.setState({
        groupByDate: args.value
      });
    }

    onShowOnlyWorkDay = (args) => {

      if(args.value===true) {
        this.setState({
          fullDay: false,
          startDayHour: 8,
          endDayHour:17
        });
      } else {
        this.setState({
          fullDay: true,
          startDayHour: 0,
          endDayHour:24 
        });
      }

    }



    onAppointmentOpened = (data) => {
       // console.log("open", data);

        data.cancel = this.state.popupCancel;

        this.setState({popupCancel:true});

    }

    inviteUpdated = (data) => {
      console.log("Updated!")

     
    }  

    inviteUpdating = (data) => {

      const updatedData = data.newData;

      console.log("updatedData",updatedData)

      let isError = false;

      if(!updatedData.description) {
          isError = true;
          pushNotification("Email is missing!");
      }

      if(!updatedData.text) {
        isError = true;
        pushNotification("Name is missing!");
      }

      if(isError){
        return;
      }

      let audioCfgObj = {
        mode: 1,
        chan: 1,
        bitrate: 64,
      };

      if(updatedData.audioQuality){
        const audioQ = updatedData.audioQuality.split('|');

        if(audioQ.length>0)
        {
          audioCfgObj = {
            mode: updatedData.audioMode,
            chan: audioQ[0],
            bitrate: audioQ[1],
          };
        }
      }

      const putData = {
        inviteGUID: updatedData.item.inviteGUID,
        sessionId: updatedData.sessionId,
        name: updatedData.text,
        email: updatedData.description,
        vcal: this.createIcalObject(updatedData),
        audioCfg: JSON.stringify(audioCfgObj),
        noemail: 1,
        
      };

     axios
     .put("./api/invite", putData, this.tokenHeader)
     .then((res) => {
        pushNotification("You Have Successfully updated the invite for " + updatedData.text, {variant: "success"});

        this.props.onCalendarUpdate(updatedData);

        this.setState({selectedDate:updatedData.endDate}, () => {
          this.schedulerRef.current.instance.option("currentDate", updatedData.endDate);
        })

     })
     .catch((err) => {

      if( err.response) {
        console.log("Invite ERROR: ", err.message, "|" , err.response.data);
        pushNotification("Error ", err.message, "|" , err.response.data);
      } else {
        console.log("Invite ERROR: ", err.message);	
        pushNotification("Error ", err.message);
      }
      // this.props.setAuthError(err);
     });

    }

    inviteAdded = (data) => {
    //  console.log("inviteAdded:", data.appointmentData)
     // console.log("ical: ", this.createIcalObject(data.appointmentData))

    
      let isError = false;

      if(!data.appointmentData.description) {
          isError = true;
          pushNotification("Email is missing!");
      }

      if(!data.appointmentData.text) {
        isError = true;
        pushNotification("Name is missing!");
      }

      if(isError){

        return;
      }

      const addNewData = data.appointmentData; 

      let audioCfgObj = {
        mode: 1,
        chan: 1,
        bitrate: 64,
      };

      if(addNewData.audioQuality){
        const audioQ = addNewData.audioQuality.split('|');

        if(audioQ.length>0)
        {
          audioCfgObj = {
            mode: addNewData.audioMode,
            chan: audioQ[0],
            bitrate: audioQ[1],
          };
        }
      }

      const postData = {
        sessionID: addNewData.sessionId,
        name: addNewData.text,
        email: addNewData.description,
        vcal: this.createIcalObject(addNewData),
        audioCfg: JSON.stringify(audioCfgObj),
        //noemail: 1,
        };

       // console.log("postData",postData)

        pushNotification("Sending Invite to: " + addNewData.text + ", please wait...", {
            variant: "success",
        });

        axios
        .post("./api/invite", postData, this.tokenHeader)
        .then((res) => {
          //console.log("invite - updated: ", res.data);
          this.props.onCalendarUpdate(addNewData);
          pushNotification("You Have Successfully sent an Invite to: " + addNewData.text, {
           variant: "success",
         });

          this.setState({selectedDate:addNewData.endDate}, () => {
            this.schedulerRef.current.instance.option("currentDate", addNewData.endDate);
          })
          
         
        })
        .catch((err) => {
           pushNotification("Error Sending Invite: " + err.message);
           console.log("ERR - add invite", err)
         // this.props.setAuthError(err);
        });
    }

    onCellClicked = (e) => {
      this.setState({popupCancel:false});
    }

    handleOpenInvite=(url) => {
      window.open(url, "_blank");
    }


    handleDeleteInvite = (data) => {

     // console.log("handleDeleteInvite", data)


      axios.delete("./api/invite?guid=" + data.item.inviteGUID, this.tokenHeader).then((res) => {
        
        this.props.onCalendarUpdate(data);
       
        this.setState({selectedDate:data.endDate}, () => {
          this.schedulerRef.current.instance.option("currentDate", data.endDate);
        })

      }).catch((err) => {
        console.log("ERR handleDeleteInvite", err)
      });
    }

    handleBanInvite = (data) => {

      console.log("handleBanInvite", data);

      const params = {
          guid:data.item.inviteGUID,
          blocked: true,
          msg: "sorry for the inconvenience"
        };

       axios.post("./api/blockInvite", params, this.tokenHeader).then((res) => {
        
         this.props.onCalendarUpdate(data);
        
         this.setState({selectedDate:data.endDate}, () => {
           this.schedulerRef.current.instance.option("currentDate", data.endDate);
         })
 
       }).catch((err) => {
         console.log("ERR handleBanInvite", err)
       });
     }

    padZero(num) {
      if (num < 10) {
        return `0${num}`;
      }
      return `${num}`;
    }

    formatDate(dateString) {
      const dateTime = new Date(dateString);
      return [
        dateTime.getUTCFullYear(),
        this.padZero(dateTime.getUTCMonth() + 1),
        this.padZero(dateTime.getUTCDate()),
        "T",
        this.padZero(dateTime.getUTCHours()),
        this.padZero(dateTime.getUTCMinutes()) + "00Z"
      ].join("");
    }

    createIcalObject = (event) => {

     
      if(event.recurrenceRule) {

        // with repeat rules
        const icalObj = [
          "BEGIN:VCALENDAR",
          "VERSION:2.0",
          "BEGIN:VEVENT",
          "DTSTART:" + this.formatDate(event.startDate),
          "DTEND:" + this.formatDate(event.endDate),
          "SUMMARY:" + event.text,
          "RRULE:" + event.recurrenceRule,
          "END:VEVENT",
          "END:VCALENDAR"
        ].join("\n");
  
        return icalObj;
  
      } else {

        // no repeat rules
        const icalObj = [
          "BEGIN:VCALENDAR",
          "VERSION:2.0",
          "BEGIN:VEVENT",
          "DTSTART:" + this.formatDate(event.startDate),
          "DTEND:" + this.formatDate(event.endDate),
          "SUMMARY:" + event.text,
          "END:VEVENT",
          "END:VCALENDAR"
        ].join("\n");
  
        return icalObj;
  

      }

    }
  
    renderPopup = (data)=>{

     // console.log("data.data", data.data );

      const curInv = data.data.appointmentData;

     // console.log("curInv", curInv );

      if(!curInv){
        return <>Missing...</>;
      }

      if(!curInv.item){
        return <>Not Saved yet...</>;
      }

      return (
        <>
         <table width="100%" style={{padding:"8px"}}>
           <tbody>
            <tr>
               <td colSpan="4"><h2><b>{curInv.text}</b></h2></td> 
            </tr>
            <tr>
               <td colSpan="4">{curInv.item.inviteSessionTitle}</td> 
            </tr>
            <tr>
              <td colSpan="4" style={{paddingTop:'5px', paddingBottom:'5px'}}><hr/></td>
            </tr>
          <tr>
            <td>
                <IconButton onClick={()=>{
                    this.setState({popupCancel:true, popupMode:1});
                    this.handleOpenInvite(curInv.item.inviteUrl);
                }} tooltip="Open Invite" icon={CallIcon}></IconButton>
            </td>

            <td>
                  <IconButton onClick={()=>{
                    this.setState({popupCancel:false, popupMode:0});
                  }} tooltip="Edit Invite" icon={EditIcon}></IconButton>
            </td>

            <td>
                <IconButton
                    icon={BlockIcon}
                    tooltip="Block and Eject"
                    onClick={async (event) => {

                      event.preventDefault();
                      if (
                        await confirm(
                          <>
                            Sure you want to Block and Eject connection for <b>{curInv.text}</b>?
                          </>,
                          { variant: "warning" }
                        )
                      ) {
                          this.handleBanInvite(curInv);
                      }
                    }}
                  />
            </td>

            <td>
                <IconButton
                    icon={DeleteIcon}
                    tooltip="Remove Invite"
                    onClick={async (event) => {

                      event.preventDefault();
                      if (
                        await confirm(
                          <>
                            Sure you want to remove <b>{curInv.text}</b>?
                          </>,
                          { variant: "warning" }
                        )
                      ) {
                          this.handleDeleteInvite(curInv);
                      }
                    }}
                  />
            </td>

          </tr>

          </tbody>
         </table>
        </>
      );
    }

    

    render() { 

        return ( 
            <React.Fragment>

                   
                    
                    <Scheduler
                      ref={this.schedulerRef} 
                      timeZone={this.state.userTimeZone}
                      dataSource={this.state.caledarData}
                      views={this.views}
                      defaultCurrentView="workWeek"
                      defaultCurrentDate={this.state.selectedDate}
                      height={600}
                      groups={this.groups}
                      groupByDate={this.state.groupByDate}
                     // cellDuration={60}
                      firstDayOfWeek={0}
              
                      startDayHour={this.state.startDayHour}
                      endDayHour={this.state.endDayHour}
                      showCurrentTimeIndicator={this.state.showCurrentTimeIndicator}
                      showAllDayPanel={false}
                    
                      shadeUntilCurrentTime={this.state.shadeUntilCurrentTime}
                      onContentReady={this.schedulerReady }
                      appointmentTooltipComponent={this.renderPopup}
                      onAppointmentFormOpening={this.onAppointmentOpened}
                      onCellClick={this.onCellClicked}
                    //  onAppointmentUpdated={this.inviteUpdated}
                      onAppointmentAdded={this.inviteAdded}
                      onAppointmentUpdating={this.inviteUpdating}

                    >

                   

                      <Resource
                        fieldExpr="sessionId"
                        allowMultiple={false}
                        dataSource={this.state.sessions}
                        label="Session"
                      />

                      <Resource
                        dataSource={this.callerAudioMode}
                        fieldExpr="audioMode"
                        label="Audio Mode"
                        allowMultiple={false}
                        defaultValue={1}
                      />

                      <Resource
                        dataSource={this.callerAudioQuality}
                        fieldExpr="audioQuality"
                        label="Audio Quality"
                        allowMultiple={false}
                        defaultValue={0}
                      />



                </Scheduler>
              

             <br />

             <Grid container spacing={2}>
                <Grid item>
                  <IconButton onClick={()=>{
                    
                    const nowDate = new Date(Date.now());
                    this.setState({selectedDate:nowDate}, () => {
                      this.schedulerRef.current.instance.option("currentDate", nowDate);
                    })

                  }} icon={RestoreIcon} tooltip="Goto Now" style={{marginTop:"10px"}} />
                </Grid>

                <Grid item>
                  <div className="caption" style={{fontSize:"12px", marginBottom:"2px"}}>Group by Date First</div>
                  <div className="option">
                  <Switch
                    value={ this.state.groupByDate }
                    onValueChanged={this.onGroupByDateChanged}
                  />
                  </div>
                </Grid>
             </Grid>



           </React.Fragment>
         );
    }
}
 
export default InviteCalendarView;