import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Panel, Button } from "@telosalliance/ui-core";
import axios from "axios";
import { pushNotification } from "@telosalliance/ui-core-framework";

import sha1 from "crypto-js/sha1";
import Base64 from 'crypto-js/enc-base64';

import FirstUse from "../pages/FirstUse";



const Login = ({ sitePadding, isLoggedIn, setIsLoggedIn, setBakedToken, setHideSideMenu, hideTopMenu, setHideTopMenu}) => {
    
    const history = useHistory();

    const [authName, setAuthName] = useState();
    const [authPwd, setAuthPwd ] = useState();

    const [firstLogin, setFirstLogin ] = useState(false);
    const [firstLoginId, setFirstLoginId ] = useState();

    useEffect(() => {
      setHideSideMenu(true); // call to useEffect on App.js that tells Routing component to hide navigation menu...
      setHideTopMenu(true);

    }, [setHideSideMenu, setHideTopMenu]);
    



    function doLogin() {

      if(!authName) {
        pushNotification("Please enter a Username", { variant: "warning" });
        return;
      }

      if(!authPwd) {
        pushNotification("Please enter a Password", { variant: "warning" });
        return;
      }

      const hashedPwd = sha1(authPwd);
      const hashedPwdB64 = Base64.stringify(hashedPwd);

      let baseUrl = document.getElementsByTagName('base')[0].baseURI; // get the url that is used (without nav pages) ex: https://www.mydomain.com/subdir1/subdir2/
      baseUrl = baseUrl.replace(window.location.origin,""); // remove the orgin Url. ex: https://www.mydomain.com

      axios.post("./api/users/authenticate", {
        email: authName,
        hash: hashedPwdB64,
        baseUrl: baseUrl
      }).then((res) => {

          if(res.data) {
            try {
              const authJSON = JSON.parse(atob(res.data.data));

              if(authJSON.ext1 === "FirstLogin") {
                
                console.log("Login - First");
                // If "First login", user *must* change password! Show the "FirstUse" component
                setBakedToken(res.data.data);
                setHideSideMenu(true);
                setHideTopMenu(false);
                setFirstLoginId(authJSON.id);
                setFirstLogin(true);
                
              } else {

                //regular login
                console.log("Login - Regular");
                setIsLoggedIn(true);
                setBakedToken(res.data.data);
                setHideSideMenu(false);
                setHideTopMenu(false);
                history.push("/dashboard");

              }
            } catch(err) {
              console.log("ERR Login read auto data", err.message);
            }  

          } else {
            // should never happens, but...

            setIsLoggedIn(false);
            setBakedToken('');
        

            sessionStorage.removeItem('tls-beacon-loggedin');  
            sessionStorage.removeItem('tls-beacon-usertoken');
            pushNotification("An Error occurred during login, please try again later...", { variant: "warning" });

          }

      }).catch((error) => {
  
        setIsLoggedIn(false);
        setBakedToken('');
        setHideSideMenu(true);
        setHideTopMenu(true);
        
        sessionStorage.removeItem('tls-beacon-loggedin');  
        sessionStorage.removeItem('tls-beacon-usertoken');

        if (error.response) {
          // normal behavior when login was unsuccesfull, 400 is returned.
          if(error.response.status === 400) {
            pushNotification("The username or password is incorrect!", { variant: "warning" });
          }

        } else if (error.request) {
          // The request was made but no response was received
          pushNotification("There Was an Error Connecting to the server: " + error.message);
        } else {
          // Something happened in setting up the request that triggered an Error
          pushNotification("Unknown Error Connecting to the server: " + error.message);
        } 
        
      });
    }

  if(firstLogin) {
    return (<FirstUse uid={firstLoginId}  />);
  
  } else {

    

    return (
      <Row justify="center" spacing={sitePadding} style={{ paddingTop: 80 }}>
        <Col spacing={sitePadding}>
        
          <Panel title="Login" style={{ width:450, minWidth: 300, maxWidth: 450, textAlign:"center"}}>
            {!isLoggedIn ? (
              <>
                  <input 
                    className="uic-input" 
                    style={{width:"100%"}} 
                    type="text" 
                    autoComplete="on" 
                    name="username" 
                    id="username" 
                    placeholder="Username"
                    onChange={(event) => {
                      setAuthName(event.target.value);
                    }} 
                    onKeyUp={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        doLogin();
                      }
                    }}
                   />
                  <br/><br/>
                  <input  
                    className="uic-input" 
                    style={{width:"100%"}} 
                    type="password" 
                    autoComplete="on" 
                    name="password" 
                    id="password" 
                    placeholder="Password" 
                    onChange={(event) => {
                      setAuthPwd(event.target.value);
                    }}
                    onKeyUp={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();
                        doLogin();
                      }
                    }}
                  />
                  <br/><br/>

                  <Button
                        
                        disabled = {!authName || !authPwd}
                        color="blue"
                        onClick={() => {
                          doLogin();
                        }}
                      >
                        Log In
                    </Button>

              </>
            ) : (
                <Link to={"./login"}>
                  <Button
                    color="blue"
                    style={{ margin: "6px" }}
                    onClick={() => {
                      setIsLoggedIn(false);
                      setBakedToken('');
                      sessionStorage.removeItem('tls-beacon-usertoken');  
                      sessionStorage.removeItem('tls-beacon-loggedin');
                      
                    }}
                  >
                    Sign Out
                  </Button>
                </Link>
              )}
          </Panel>
        </Col>
      </Row>
    );

  }
};

export { Login };
