import React, { Component } from 'react'

import { 
    pushNotification,  
    confirm
  } from "@telosalliance/ui-core-framework";

import {
    Row,
    Panel,
    //CollapsablePanel,
    Table,
    Toggle,
    Button,
   // IconButton,
    Modal,
    
  } from "@telosalliance/ui-core";

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import axios from "axios";

import { format, formatDistanceToNow } from 'date-fns'

import InviteCalendarView from "./InviteCalendarView";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ListIcon from '@material-ui/icons/List';
import CollapsablePanelDashboard from "../component/CollapsablePanelDashboard";

export class UserInvites extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            agentGroupItems:[],
            agentGroupCount:0,
            curAgentGroupStart:0,
            curAgentGroupPage:25,
            agentGroupLoaded:false,
            panelCollapsed: true,
            agentGroupGUID: '', 
            userGUID: '',
            isEditMode:false,
            editModalOpen:false,
            curEditInvite:{},
            userInviteListMode:true,
            userRole:'',
            userTimeZone:'',
            calData: [],
            calSessions:[],
            lastChanged:0,
            selectedDate: new Date(Date.now()),
        };
    
        // using this method since state is async, and can't be read direct after set.
        this.tokenHeader = "";
          
      }

    getToken() {
        // get "bakedToken" from session (session gets populated via login, remove __STRING__ which is added by Daren's "useSessionStorage")
        if(sessionStorage.getItem('tls-beacon-usertoken')) {
          const bakedToken = sessionStorage.getItem('tls-beacon-usertoken').replace("__STRING__", "");
          const bakedJson = JSON.parse(atob(bakedToken)); //decode base64

         // console.log("bakedJson",bakedJson)

          this.tokenHeader = { headers: { 'Authorization': 'Bearer ' + bakedJson.token, 'Content-Type': 'application/json' } }

          // use the callback in setState, this so we really have the values in the state before we use them.
          this.setState({ agentGroupGUID: bakedJson.agentGroupGuid, userGUID: bakedJson.userGuid , userRole: bakedJson.role, userTimeZone: bakedJson.timeZone }, () => {
            // load local stuff
            this.loadAgentsInGroup(0);
          });

        } else {
          this.props.setAuthError("noToken");
        }
    }  

    componentDidMount() {
      
      this.setState({panelCollapsed:true})
      this.getToken();
    }

    loadAgentsInGroup(agentStart){

        axios.get("./api/invitesbygroup?agentGroupGUID="+ this.state.agentGroupGUID + "&start=" + agentStart + "&page=" + this.state.curAgentGroupPage, this.tokenHeader).then((res) => {

         // console.log("userItems", res.data.data);

           this.setState({agentGroupItems: res.data.data, agentGroupCount: res.data.count, agentGroupLoaded: true });
           this.setState({curAgentGroupStart: agentStart, lastChanged: new Date().getTime()})

          }).catch((err) => {
            this.props.setAuthError(err);
          });
  
      }

    handleEditInvite = (guid) => {

        axios.get("./api/invite?inviteGUID=" + guid + "&events=true",  this.tokenHeader).then((res) => {
            const data = res.data.data;
            //console.log("handleEditInvite", data)

            this.setState({editModalOpen: true, isEditMode:true, curEditInvite: data });

            }).catch((err) => {
                console.log("ERR handleEditInvite", err)
                 this.props.setAuthError(err);
            });
    }; 
    
    handleOpenInvite=(item) => {
      window.open(item.inviteUrl, "_blank");
    }


    renderAgentsPagination() {

        let _start = this.state.curAgentGroupStart;
        let _page = this.state.curAgentGroupPage;
        let _count = this.state.agentGroupCount;

        // if number of items (Agents) are more than our page size then show pagnation buttons.
        if(_count > _page) {

            return ( 
                <>
                <Row>
                    <Button onClick={() => {
                        if(_start - _page >= 0) {
                            this.loadAgentsInGroup(_start - _page);
                        }
                    }}>{'<'}</Button>
                    <div style={{marginLeft: '5px', marginRight:'5px'}}> {_start/_page + 1} / { Math.ceil(_count/_page)} </div>
                    <Button onClick={() => {
                        if(_start + _page < _count) {
                            this.loadAgentsInGroup(_start + _page);
                        }
                    }}>{'>'}</Button>
                </Row>
                <Divider style={{marginTop:'5px'}} />
            </>);   
            
        }
            
    }

    getAge(seconds) {
        return formatDistanceToNow(
        new Date(seconds*1000)
      );
    }

    getTime(seconds) {
      return format(new Date(seconds*1000), "MM-dd HH:mm:ss");
    }

    getAgeUTC(seconds) {

       let d = new Date(0);
       d.setUTCSeconds(seconds); 
      return formatDistanceToNow(d);
    }

    

    handleEditInviteItem =  (event) => {
        // console.log(event.target.id, event.target.value);
        const dataType = event.target.getAttribute("data-type");
        const item = event.target.id;
        const data = event.target.value;
    
        const updatedItems = { ...this.state.curEditInvite };
        if (dataType) {
          switch(dataType){
            case "int":
              updatedItems[item] = parseInt(data);  
            break;
            case "double":
              updatedItems[item] = parseFloat(data);  
            break;
            case "bool":
              updatedItems[item] = JSON.parse(data);  
            break;
            case "string":
              updatedItems[item] = data;  
            break;
            default:
              updatedItems[item] = data;  
              break;
          }
        } else {
          updatedItems[item] = data;  
        }

        this.setState({ curEditInvite: updatedItems });
    }

    handleEditInviteBlocked = (value) => {
        const updatedItems = { ...this.state.curEditInvite };
        updatedItems["isBlocked"] = value;
        this.setState({ curEditInvite: updatedItems });
    }

    handleEditInviteSave = (item) => {

        if(this.state.isEditMode) {
            
            const putData = {
                inviteGUID: this.state.curEditInvite.inviteGUID,
                inviteName: this.state.curEditInvite.inviteName,
                inviteEmail: this.state.curEditInvite.inviteEmail,
                isBlocked: this.state.curEditInvite.isBlocked,
            }

            //console.log("update:", putData)

            axios
            .put("./api/inviteLite", putData, this.tokenHeader)
            .then((res) => {
              //console.log("RECEIVED: ", res.data);
              this.setState({ editModalOpen: false, isEditMode:false });
              this.loadAgentsInGroup(this.state.curAgentGroupStart);
            })
            .catch((err) => {

              console.log("ERR handleEditInviteSave", err)
              this.props.setAuthError(err);
            });


        }
    }

    handleEditInviteReSend = (item) => {
        //this.state.curEditInvite.inviteGUID

        pushNotification("Sending Invite to: " + this.state.curEditInvite.inviteName + ", please wait...", {
            variant: "success",
        });

        this.setState({ editModalOpen: false, isEditMode:false });

        const guid = this.state.curEditInvite.inviteGUID;

        axios
        .post("./api/resendinvite?guid=" + guid, this.tokenHeader)
        .then((res) => {
          //console.log("RECEIVED: ", res.data);
          

            pushNotification("Invite was sent to: " + this.state.curEditInvite.inviteName, {
                variant: "success",
            });
        })
        .catch((err) => {

          console.log("ERR handleEditInviteSave", err)
         // this.props.setAuthError(err);
        });
    }

    
    handleDeleteInvite = (guid) => {

        axios.delete("./api/invite?guid=" + guid, this.tokenHeader).then((res) => {
            this.loadAgentsInGroup(this.state.curAgentGroupStart);
        }).catch((err) => {
           console.log("ERR handleDeleteInvite", err)
           this.props.setAuthError(err);
        });
    }

    renderModalEdit() {

        let editModeTitle = "Add New";
        if(this.state.isEditMode) {editModeTitle = "Edit";}

        return (
          <Modal
              key="modalE1"
              open={this.state.editModalOpen}
              className="session-inviteModal"
              onEscapeKeyDown={() => this.setState({ editModalOpen: false })}
              buttons={[
                <button key="usi_btnReSend" type="button" className="uic-btn btn-gray"
                onClick={async (event) => {
                   // event.preventDefault();
                    if (
                        await confirm(
                        <>
                            Sure you want to re-send the invite to<br /><b>{this.state.curEditInvite.inviteName}</b>?
                        </>,
                        { variant: "warning" }
                        )
                    ) {
                        this.handleEditInviteReSend()
                    }
                    }}
                >Re-Send Invite</button>,
                
                <button key="usi_btnCancel" type="button" className="uic-btn btn-grey" onClick={() => this.setState({ editModalOpen: false })} >Cancel</button>,
                <button key="usi_btnSave" type="button" className="uic-btn btn-primary" onClick={() => this.handleEditInviteSave()} color="primary">Save</button>,
              ]}
            >
              <h2 className="panel-subSection-header">{editModeTitle}</h2>
              
                {this.state.curEditInvite.isValid === false && ( 
                  <Chip key="chipE1" size="small" label="Invite has exprired" color="secondary" />
                )}
               
              <Table
                key="usi_tbl"
                alignLabelsLeft
                yPad={10}
                rows={[
                  ["Name", 
                  <input 
                    key="usi_name"
                    className="uic-input" 
                    id="inviteName" 
                    placeholder="URL" 
                    name="inviteName" 
                    autoComplete="off" 
                    type="text"
                    required 
                    defaultValue={this.state.curEditInvite.inviteName}
                    onChange={this.handleEditInviteItem} 
                    data-type="string" 
                  />],
                  ["Email", 
                  <input 
                    key="usi_email"
                    className="uic-input" 
                    id="inviteEmail" 
                    placeholder="Email" 
                    name="inviteEmail" 
                    autoComplete="off" 
                    type="text"
                    required 
                    defaultValue={this.state.curEditInvite.inviteEmail}
                    onChange={this.handleEditInviteItem} 
                    data-type="string" 
                  />],
                  
                  ["Blocked", 
                    <Toggle 
                    key="usi_blocked"
                    showText
                    onChange={this.handleEditInviteBlocked} 
                    defaultChecked={this.state.curEditInvite.isBlocked}
                    id="isBlocked" 
                    name="isBlocked" 
                    data-type="bool" 
                    />],
                  
                ]}
              />
               
            </Modal>
        )
    }

    
    onCalendarUpdate = (data) => {

      this.setState({selectedDate:data.endDate})

      // reload data
      this.loadAgentsInGroup(this.state.curAgentGroupStart);
    }


    renderUserInviteCalendar(items) {

     // console.log("renderUserInvate",items)

      let calData=[];
      const agentGuid = items.agentGUID;
      const agentGroupGUID = items.agentGroupGUID;
      items.invites.forEach((item, key, map) => {

        //console.log("item",item)

          const audioCfg = JSON.parse(item.audioConfig);

          const inv = {
            text: item.inviteName,
            startDate: new Date(item.dtStart * 1000),
            endDate: new Date(item.dtEnd * 1000),
            recurrenceRule: item.rRule,
            sessionId: item.inviteSessionId,
            email: item.inviteEmail,
            description: item.inviteEmail,
            item: item,
            agentGuid: agentGuid,
            agentGroupGUID: agentGroupGUID,
            audioMode: audioCfg.mode,
            audioQuality: audioCfg.chan + "|" + audioCfg.bitrate,
          };

          calData.push(inv);
    
        });

      let calSessions=[];  

      items.sessions.forEach((item, key, map) => {
          const ses = {
            text: item.sessionTitle,
            id: item.sessionID
          };
          calSessions.push(ses);
      });

      return (
      <>
        <InviteCalendarView selectedDate={this.state.selectedDate} lastChanged={this.state.lastChanged} calData={calData} calSessions={calSessions} onCalendarUpdate={this.onCalendarUpdate} />
      </>
      )      
    }



    renderSessions(items) {
      return (
        <>
          <Panel title="Sessions">
            <Table 
                    params 
                    striped
                    alignLabelsLeft="left"
                    headers={["#","Name","Last seen",""]}
                    columnWidths={[20, 0, 150,80]}
                    rows={
                    items.sessions.map((ses, index) => 
                        [(index+1),ses.sessionTitle, 
                          this.getAgeUTC(ses.lastSeenDateSec),
                          <button key={`sndi_btn_${index}`}  type="button" className="uic-btn btn-grey ">Send Invite</button>, 
                        ]
                    )}
                /> 
          </Panel>
        </>
      )
    }

    agentCallback = (isOK, msg) =>{
      if(isOK) { 
        console.log("agentCallback", isOK, msg);
        this.loadAgentsInGroup(0);
      }
  }

    render() {

        if(this.state.agentGroupGUID === ''){
          return (<>
            <h2>Agents info missing, please login again!</h2>
          </>);
        }

        return (
           <>
                {/*this.renderModalEdit()*/}

                <Grid container spacing={2}>
                  <Grid item sm={12}>
                    <Panel>
                    {this.renderAgentsPagination()}

                    {(!this.state.agentGroupLoaded) && ( 
                        <>
                         <div> <HourglassEmptyIcon style={{color:"#f58838"}} /> <span style={{position:"relative", top:"-5px"}}>Loading...</span></div>
                        </>
                    )}

                    {(this.state.agentGroupLoaded && this.state.agentGroupCount === 0) && ( 
                        <h3>No Agents found</h3>
                    )}

                    {this.state.agentGroupItems.map((itemAgent) => (    
                      <CollapsablePanelDashboard userRole={this.state.userRole} callBack = {this.agentCallback} style={{backgroundColor:"#181818"}} id={`db_pnl_${itemAgent.agentGUID}`} key={itemAgent.agentGUID} startCollapsed={true} dataObj={itemAgent} token={this.tokenHeader} title={<><ListIcon style={{color:"#48ACF4", position:"relative", top:"5px"}}  /> {itemAgent.title} <span style={{color:"#48ACF4"}}>({itemAgent.invitesCount})</span></>} spacing={2}>

                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >  
 
                            {this.renderUserInviteCalendar(itemAgent)}
                          </Grid>
                        </Grid>
                    </CollapsablePanelDashboard>
                    ))}
                    </Panel>
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default UserInvites
